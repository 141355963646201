



















 import { Page, Sort, Filter, Edit, Toolbar } from "@syncfusion/ej2-vue-grids";
import { Component, Vue } from 'vue-property-decorator'
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import {MyGridOption} from '@/Helper/MyDataAdapter';
import AddEnroll from "./Component/AddEnroll.vue";
Vue.use(GridPlugin);
@Component({
     components: {
  },
  provide:{
     grid: [Page, Sort, Filter, Edit, Toolbar]
  }
})
export default class Student extends Vue {
  dataManager = MyGridOption.getDataManager('EnrollCourseAdminList');
  filterOptions = {    type: 'Menu'};
  wrapSettings= { wrapMode: 'Both ' };
  
  editSettings= { allowAdding: true, mode: 'Dialog',showDeleteConfirmDialog:true,  template: function() {
          return { template: AddEnroll };
        } };
    toolbar= [ 'Add'];
    actionFailure(args:any) {
      this.$store.commit("ChangeLoading", false);
      console.log(args);
      var t = args.error[0].error.response;
      console.log(t);
       this.$bus.$emit('testing_call',{data:t});
}
}
