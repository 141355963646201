































import { Component, Vue } from 'vue-property-decorator'
import { DropDownListPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { MyGridOption } from '@/Helper/MyDataAdapter';
Vue.use(DropDownListPlugin);
@Component({
    components: {
  }
})
export default class AddEnroll extends Vue {
 
   data:any={};
   Teachers = MyGridOption.getDataManager('TeacherAdminList');
   Sessions = MyGridOption.getDataManager('SessionAdminList');
   Student = MyGridOption.getDataManager('StudentAdminList');
   Varient:any = [];
    ChangeSession(args:any){
      this.Varient = args.itemData.Varients;
    }
}
